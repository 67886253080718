import { ChangeDetectorRef, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CarouselCardsEntity } from '../../../../functions/src/models/general/carousel-cards.entity';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { CarrosselService } from '../../customer/services/carrossel.service';
import { CustomerPanelLogoFileUploadComponent } from '../customer-panel-logo-file-upload/customer-panel-logo-file-upload.component';

@Component({
  selector: 'app-customer-panel-new-card',
  templateUrl: './customer-panel-new-card.component.html',
  styleUrls: ['./customer-panel-new-card.component.scss']
})
export class CustomerPanelNewCardComponent implements OnInit {
 
  @Input() carouselCardsEntity: CarouselCardsEntity;
  faixaFaturamento: { revenueMin: number; revenueMax: number }[] = [];
  createFormGroup: FormGroup;
  items: CarouselCardsEntity[];
  id: string;
  isLoading = false;
 

  constructor(private dialog: MatDialog,private fb: FormBuilder, private carrosselService: CarrosselService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CustomerPanelNewCardComponent>,private cdr: ChangeDetectorRef) {
    if (!data) {
      console.warn('Nenhum dado foi passado para o modal.');
    }
  }
  @ViewChild(CustomerPanelLogoFileUploadComponent, { static: false }) customerPanelLogoFileUpload: CustomerPanelLogoFileUploadComponent;

 
  ngOnInit(): void {
    this.onLoadForm();
 
    }
  

  onLoadForm(): void {
    this.createFormGroup = new FormGroup({
    

      nameCtrl: new FormControl(this.carouselCardsEntity?.name || '', ),
      textCtrl: new FormControl(this.carouselCardsEntity?.text, Validators.required),
      visiblePanelCtrl: new FormControl(this.carouselCardsEntity?.visiblePanel || ''),
      descricaoCtrl: new FormControl(this.carouselCardsEntity?.subText || ''),
      urlCtrl: new FormControl(this.carouselCardsEntity?.link, Validators.required),
      taxRegimesCtrl: new FormControl(this.carouselCardsEntity?.taxRegimes || '' ),
      guaranteesCtrl: new FormControl(this.carouselCardsEntity?.guarantees || ''),
      purposeCtrl: new FormControl(this.carouselCardsEntity?.purpose || ''),
      tipoCtrl: new FormControl(this.carouselCardsEntity?.type || ''),
      revenueMinCtrl: new FormControl(this.carouselCardsEntity?.revenueMin || 0 ),
      revenueMaxCtrl: new FormControl(this.carouselCardsEntity?.revenueMax ||  0),
      logoCtrl: new FormControl(this.carouselCardsEntity?.imageLink ),
      
      
      
      
    });
    
    if (this.data) {
      this.createFormGroup.patchValue({
        nameCtrl: this.data.name,
        textCtrl: this.data.text,
        visiblePanelCtrl: this.data.visiblePanel,
        descricaoCtrl: this.data.subText,
        urlCtrl: this.data.link,
        taxRegimesCtrl: this.data.taxRegimes,
        guaranteesCtrl: this.data.guarantees,
        purposeCtrl: this.data.purpose,
        tipoCtrl: this.data.type,
        revenueMinCtrl: this.data.revenueMin || 0,
        revenueMaxCtrl: this.data.revenueMax || 0 ,
        logoCtrl: this.data.imageLink ,
     
      });
    
    }
  }



  onSaveData(): void {
    this.isLoading = true; 
  
    try {
      this.setImagemLogo();
  
      const formData = {
        id: this.data?.id,
        name: this.createFormGroup.value.nameCtrl,
        text: this.createFormGroup.value.textCtrl,
        visiblePanel: this.createFormGroup.value.visiblePanelCtrl,
        subText: this.createFormGroup.value.descricaoCtrl,
        link: this.createFormGroup.value.urlCtrl,
        taxRegimes: this.createFormGroup.value.taxRegimesCtrl,
        guarantees: this.createFormGroup.value.guaranteesCtrl,
        purpose: this.createFormGroup.value.purposeCtrl,
        type: this.createFormGroup.value.tipoCtrl,
        revenueMin: this.createFormGroup.value.revenueMinCtrl,
        revenueMax: this.createFormGroup.value.revenueMaxCtrl,
        imageLink: this.createFormGroup.value.logoCtrl
      };
  
      if (formData.id) {
        this.carrosselService.updateFormData(formData)
          .then(() => {
            console.log('Dados atualizados com sucesso no Firestore!');
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Registro salvo',
                alertDescription: 'O registro foi salvo com sucesso.',
                isOnlyConfirm: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.isLoading = false; 

              this.onLoadForm();
              this.dialogRef.close(); 
            });
          })
          .catch((error) => {
            console.error('Erro ao atualizar os dados no Firestore:', error);
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao salvar',
                alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.isLoading = false; 
              this.dialogRef.close(); 
            });
          });
      } else {
        // Caso não tenha ID, salva um novo registro
        this.carrosselService.saveFormData(formData)
          .then(() => {
            console.log('Dados salvos com sucesso no Firestore!');
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Registro salvo',
                alertDescription: 'O registro foi salvo com sucesso.',
                isOnlyConfirm: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.isLoading = false; 
              this.onLoadForm();
              this.dialogRef.close(); 
            });
          })
          .catch((error) => {
            console.error('Erro ao salvar os dados no Firestore:', error);
            const dialogRef = this.dialog.open(AlertDialogComponent, {
              maxWidth: '600px',
              data: {
                alertTitle: 'Erro ao salvar',
                alertDescription: 'Não foi possível salvar o registro. Tente novamente mais tarde.',
                isOnlyConfirm: true,
              },
            });
            dialogRef.afterClosed().subscribe(() => {
              this.isLoading = false; 
              this.dialogRef.close(); 
            });
          });
      }
    } catch (error) {
      console.error('Erro no processo de salvar dados:', error);
      this.isLoading = false; 
    }
  }
  

  addRange() {
   
    this.faixaFaturamento.push({
      revenueMin: 0,
      revenueMax: 0  
    });
  }

  removeRange(index: number) {
    this.faixaFaturamento.splice(index, 1);
  }

  onClose(): void {
    this.dialogRef.close(); 
  }

  setImagemLogo(): void {
    const logoUrl = this.customerPanelLogoFileUpload.getImageUrl() || this.createFormGroup.controls.logoCtrl.value;
    this.createFormGroup.controls.logoCtrl.setValue(logoUrl)
  }

  deleteLogo(): void {
    this.createFormGroup.controls.logoCtrl.setValue(null)

    this.cdr.detectChanges();

  }
 

}
