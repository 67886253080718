<div class="user-credit-lines-container">
  <div class="inner-container">
    <div class="content-wrapper">
      <div class="main-content">
        <div class="user-credit-lines-content">
          <h1 class="mb-4">Ofertas de crédito</h1>

          <p *ngIf="isCustomerNoTouch">
            Nesta página você receberá suas <b>Ofertas de crédito</b> e poderá <b>escolher a ideal</b> para sua empresa. Ao dar andamento em uma oferta, a instituição parceira pode entrar em contato para prosseguir com maior agilidade!
          </p>

          <p *ngIf="!isCustomerNoTouch">
            Nesta página você receberá suas <span><strong>ofertas de crédito</strong></span> e poderá
            <span><strong>escolher a ideal</strong></span> para sua empresa!
            Para isso, carregue seus documentos pendentes na aba <a [routerLink]="['/documentacao']" style="color: #FF7E1D;"><strong>Meus
              Documentos</strong></a>.

          </p>
          <div class="div-healp">
            <a *ngIf="!enableLoading && hasOppsByRecurrence" (click)="openDialog()" mat-flat-button color="primary"
              class="action-button p-l-2 p-r-2 ml-0 ml-sm-3" type="button">
              Peça crédito novamente!
            </a>
          </div>
          <div class="text-center" *ngIf="enableLoading">
            <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
          </div>
        </div>

        <div class="example-card-container">
          <mat-card class="example-card">
            <mat-card-content>
            <mat-card-title>Meu pedido</mat-card-title>
            <div >
              <p>Valor pedido: {{ customer?.creditValueStr }}</p>
              <p>Garantias: {{ guarantees }}</p>
              <p *ngIf="customer?.guaranteesValueStr">Valor total das garantias: {{ customer?.guaranteesValueStr }}</p>

            </div>
            <div class="example-card-btn">
              <span>Receba mais ofertas!</span>
              <button type="button" (click)="clickAddGuarantee()" *ngIf="!customer?.guaranteesValueStr"> Adicione uma garantia </button>
              <button type="button" (click)="clickAddGuarantee()" *ngIf="customer?.guaranteesValueStr"> Adicione outra garantia </button>
            </div>
          </mat-card-content>
          </mat-card>

          <mat-card *ngIf="bndesProposal" class="card-score">
            Meu score de crédito: <span> {{bndesProposal?.creditScore}}
            <mat-icon (mouseover)="isScoreHintVisible = true" (mouseout)="isScoreHintVisible = false">help_outline</mat-icon></span>
            <mat-hint *ngIf="isScoreHintVisible" class="scoreHint">Score é uma pontuação para avaliar
              o histórico financeiro do consumidor. Quanto maior a pontuação, melhor a avaliação de
              crédito do consumidor</mat-hint>
          </mat-card>
        </div>

      </div>

      <div *ngIf="!enableLoading">
        <div class=" d-flex content">
          <p>
            Precisa de ajuda?
          </p>
          <div class="button-ajuste">
          <button style="width: 141px;" class="button-custom" routerLink="/mensagens">Fale conosco</button>
          <button style="width: 201px;" class="button-custom" >
            <a href="https://www.capitalempreendedor.com.br/faq" target="_blank" style=" color: #008348;">Perguntas frequentes</a>

          </button>
        </div>
          <button class="button-planilha" (click)="exportOpportunities()">Exportar ofertas para Planilha</button>
        </div>
        <mat-tab-group *ngIf="opportunities" #tabGroup [ngClass]="opportunityHistory?.length > 0  ? 'custom-tab-styles' : 'no-custom-styles'  ">
          <ng-container *ngIf="opportunityHistory?.length > 0">
          <mat-tab label="Linhas atuais">
            <div class="opportunity-cards">
              <div class="row">
                <div class="col-12 col-md-4" *ngFor="let operacao of opportunities">
                  <div class="custom-card" [class]="getCardBorderColorClass(operacao)">
                    <div class="card-header">
                      <div class="d-flex align-items-center"
                        style=" justify-content: space-between; color: #00C46C; height: 30px; margin: 10px;">
                        <ng-container *ngIf="operationToCustomerVisibleCondition(operacao)">
                          <img src="{{ operacao?.logoPath }}" alt="">
                        </ng-container>
                        <ng-container *ngIf="iconCheck(operacao)">
                          <img src="../../../assets/images/icons/check.png" alt="" style="margin-left: auto;">
                        </ng-container>

                        <ng-container *ngIf="iconX(operacao)">
                          <img src="../../../assets/images/icons/x.png" alt="" style="margin-left: auto;" >
                        </ng-container>
                        <ng-container *ngIf="!iconCheck(operacao) && !iconX(operacao) ">
                        <!-- <mat-icon  (click)="onOpenProposalDialog(operacao)">search</mat-icon> -->
                      </ng-container>
                      </div>
                      <h3>{{ operationToCustomerVisibleText(operacao) }}</h3>
                    </div>

                    <div class="card-content" *ngIf="!isExpired(operacao)">
                      <h4 [class.waiting-for-response]="isWaitingForCustomerResponse(operacao)">{{
                        operacao?.stageSituation }}</h4>

                      <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button" class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                        Ver detalhes
                        <img style="margin-left: 6px;" src="../../../assets/images/icons/Arrow Right.png" alt="">
                      </button>

                      <p *ngIf="operacao.institutionKey != 'zippi'"><strong>Prazo:</strong> {{ operacao?.deadlineForMonth + " meses" || '-' }}</p>
                      <p *ngIf="operacao.institutionKey != 'zippi'"><strong>Taxa:</strong> {{ operacao?.lineActive && operacao?.rate ? operacao?.rate + '% ao mês' : '-' }}</p>


                      <p *ngIf="operacao.institutionKey === 'zippi'"><strong>Prazo:</strong> {{ operacao?.deadlineForMonth + " semana"|| '-' }}</p>
                      <p *ngIf="operacao.institutionKey === 'zippi'"><strong>Taxa:</strong>A partir de {{ operacao?.lineActive && operacao?.rate ? operacao?.rate + '%' : '-' }}</p>

                      <button routerLink="/documentacao" *ngIf="operacao?.stageSituation === 'Pendente assinatura SCR'" mat-raised-button type="button" class="button-detalhe" >
                        Assinar agora
                        <img style="margin-left: 6px;" src="../../../assets/images/icons/Arrow Right.png" alt="">
                      </button>

                      <!-- <p><strong>Prazo:</strong> {{ operacao?.deadlineForMonth + " meses" || '-' }}</p>
                      <p><strong>Taxa:</strong> {{ operacao?.lineActive && operacao?.rate ? operacao?.rate + '% ao mês' : '-' }}</p>
                      <p><strong>Limite Operação:</strong> {{ !operationToCustomerVisibleCondition(operacao) ? '-' : operacao?.lineActive && operacao?.limitOperation |
                        currency: 'BRL':true:'1.2-2' || '-' }}</p>

                        <p><strong>Garantia:</strong> {{ operacao?.usedWarranty || '-' }}</p>
                        <p><strong>Linha:</strong> {{ operacao?.usedLine || '-' }}</p>
                       <p><strong>Validade da proposta:</strong> {{ calcValidityMessage(operacao) }}</p>  -->
                    </div>
                    <div *ngIf="isExpired(operacao)" class="card-content">
                      <div>
                        <h4 style="color: #F93232;">Proposta expirada</h4>
                      </div>

                      <div>
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button" class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                          Ver detalhes <img style="margin-left: 6px;" src="../../../assets/images/icons/Arrow Right.png" alt="">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </ng-container>

        <ng-container *ngIf="opportunityHistory?.length === 0">
          <mat-tab >
            <div class="opportunity-cards">
              <div class="row">
                <div class="col-12 col-md-4" *ngFor="let operacao of opportunities">
                  <div class="custom-card" [class]="getCardBorderColorClass(operacao)">
                    <div class="card-header">
                      <div class="d-flex align-items-center"
                        style=" justify-content: space-between; color: #00C46C; height: 30px; margin: 10px;">
                        <ng-container *ngIf="operationToCustomerVisibleCondition(operacao)">
                          <img src="{{ operacao?.logoPath }}" alt="">
                        </ng-container>
                        <ng-container *ngIf="iconCheck(operacao)">
                          <img src="../../../assets/images/icons/check.png" alt="" style="margin-left: auto;">
                        </ng-container>

                        <ng-container *ngIf="iconX(operacao)">
                          <img src="../../../assets/images/icons/x.png" alt="" style="margin-left: auto;" >
                        </ng-container>
                        <ng-container *ngIf="!iconCheck(operacao) && !iconX(operacao) ">
                        <!-- <mat-icon  (click)="onOpenProposalDialog(operacao)">search</mat-icon> -->
                      </ng-container>
                      </div>
                      <h3>{{ operationToCustomerVisibleText(operacao) }}</h3>
                    </div>

                    <div class="card-content" *ngIf="!isExpired(operacao)">
                      <h4 [class.waiting-for-response]="isWaitingForCustomerResponse(operacao)">{{
                        operacao?.stageSituation }}</h4>

                      <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button" class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                        Ver detalhes
                        <img style="margin-left: 6px;" src="../../../assets/images/icons/Arrow Right.png" alt="">
                      </button>

                      <button routerLink="/documentacao" *ngIf="operacao?.stageSituation === 'Pendente assinatura SCR'" mat-raised-button type="button" class="button-detalhe" >
                        Assinar agora
                        <img style="margin-left: 6px;" src="../../../assets/images/icons/Arrow Right.png" alt="">
                      </button>

                      <!-- <p><strong>Prazo:</strong> {{ operacao?.deadlineForMonth + " meses" || '-' }}</p>
                      <p><strong>Taxa:</strong> {{ operacao?.lineActive && operacao?.rate ? operacao?.rate + '% ao mês' : '-' }}</p>
                      <p><strong>Limite Operação:</strong> {{ !operationToCustomerVisibleCondition(operacao) ? '-' : operacao?.lineActive && operacao?.limitOperation |
                        currency: 'BRL':true:'1.2-2' || '-' }}</p>

                        <p><strong>Garantia:</strong> {{ operacao?.usedWarranty || '-' }}</p>
                        <p><strong>Linha:</strong> {{ operacao?.usedLine || '-' }}</p>
                      <p><strong>Validade da proposta:</strong> {{ calcValidityMessage(operacao) }}</p>  -->
                    </div>



                    <div *ngIf="isExpired(operacao)" class="card-content">

                      <div >
                        <h4 style="color: #F93232;">Proposta expirada</h4>
                      </div>

                      <div>
                        <button *ngIf="showDetailsButton(operacao)" mat-raised-button type="button" class="button-detalhe" (click)="onOpenProposalDialog(operacao)">
                        Ver detalhes
                      <img style="margin-left: 6px;" src="../../../assets/images/icons/Arrow Right.png" alt="">
                      </button>
                      </div>

                      <ng-container >

                      </ng-container>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
        </ng-container>

        <!-- <mat-tab *ngFor="let group of opportunityHistoryGroups">
          <ng-template mat-tab-label>
            {{ group.label }}
          </ng-template>

          <table mat-table [dataSource]="group.dataSource" class="table-generic-styles">

            <ng-container matColumnDef="img">
              <th mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let history">
                <div *ngIf="getLogoPath(history)">
                  <img [src]="getLogoPath(history)" width="50px" />
                </div>
                <div *ngIf="!getLogoPath(history)">
                  <mat-icon>account_balance</mat-icon>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="institutionName">
              <th mat-header-cell *matHeaderCellDef>Instituição</th>
              <td mat-cell *matCellDef="let history">{{ history.institutionName }}</td>
            </ng-container>

            <ng-container matColumnDef="deadlineForMonth">
              <th mat-header-cell *matHeaderCellDef>Prazo em meses</th>
              <td mat-cell *matCellDef="let history">{{ history.deadlineForMonth }}</td>
            </ng-container>

            <ng-container matColumnDef="limitOperation">
              <th mat-header-cell *matHeaderCellDef>Valor do Crédito</th>
              <td mat-cell *matCellDef="let history">{{ history.limitOperation | currency: 'BRL':true:'1.2-2' }}</td>
            </ng-container>

            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef>Taxa</th>
              <td mat-cell *matCellDef="let history">{{ history.rate }} %</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="opportunityHistoryColumns"></tr>

            <tr mat-row *matRowDef="let row; columns: opportunityHistoryColumns"></tr>
          </table>
        </mat-tab> -->

        </mat-tab-group>
        <div class="loading-container" *ngIf="!opportunities">
          <mat-spinner></mat-spinner>
        </div>
        <div class="cta-documents" *ngIf="!isCustomerNoTouch">
          <p>Termine de enviar seus documentos para receber mais propostas!</p>
          <button><a href="/documentacao">Enviar documentos</a></button>
        </div>
      </div>
    </div>
  </div>
</div>
