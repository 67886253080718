export class ReceivablesFormatter {
  static format(data: any[]): any[] {
    return data.map((item) => {
      // Mapeamento de códigos de forma de pagamento para descrições
      const formaPagamentoMap: { [key: string]: string } = {
        ADI: 'Adiantamento',
        BOL: 'Boleto',
        CRT: 'Cartão',
        CHQ: 'Cheque',
        CON: 'Convênios',
        CRE: 'Crediário',
        DRF: 'DARF',
        DAS: 'DAS',
        DEB: 'Débito em conta',
        DIN: 'Dinheiro',
        DOC: 'DOC',
        GUIA: 'Guia de Recolhimento',
        PROT: 'Protesto',
        REC: 'Recibo',
        RPA: 'RPA',
        TED: 'TED',
        TRA: 'Transferência',
        '99999': 'Outros',
        BANKING_BILLET: 'Boleto Bancário',
        CASH: 'Dinheiro',
        CHECKINGACCOUNT: 'Conta Corrente',
      };

      if (item.detalhes) {
        // Primeiro formato ("movimentos" com "detalhes" e "resumo")
        return {
          recebimento: item.detalhes.dDtPagamento || '-',
          formaPagamento: formaPagamentoMap[item.detalhes.cTipo] || 'Desconhecido',
          parcela: item.detalhes.cNumParcela,
          taxaOperacao: 'N/A', // Taxa de operação não presente nesse formato
          valorParcela: item.detalhes.nValorTitulo,
          valorLiquido: item.resumo.nValLiquido,
          valorRecebido: item.resumo.nValPago,
          valorTotal: item.detalhes.nValorTitulo,
          situacao: item.resumo.cLiquidado === 'S' ? 'quitado' : 'aberto',
        };
      } else if (item.payment && item.payment.installments) {
        // Segundo formato (Conta Azul com "payment" e "installments")
        const installment = item.payment.installments[0]; // Considera a primeira parcela
        return {
          recebimento: installment?.due_date || '-',
          formaPagamento: formaPagamentoMap[item.payment.method] || 'Desconhecido',
          parcela: `${installment?.number}/${item.payment.installments.length}`,
          taxaOperacao: item.discount?.rate ? `${item.discount.rate}%` : 'N/A',
          valorParcela: installment?.value || 0,
          valorLiquido: installment?.value || 0,
          valorRecebido: installment?.status === 'PAID' ? installment?.value : 0,
          valorTotal: item.total || 0,
          situacao: installment?.status === 'PAID' ? 'quitado' : 'aberto',
        };
      } else {
        // Caso o formato seja desconhecido, retorna valores padrão
        return {
          recebimento: '-',
          formaPagamento: 'Desconhecido',
          parcela: '-',
          taxaOperacao: '-',
          valorParcela: 0,
          valorLiquido: 0,
          valorRecebido: 0,
          valorTotal: 0,
          situacao: 'desconhecido',
        };
      }
    });
  }
}
