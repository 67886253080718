<mat-dialog-content class="dialog-container">
  <!-- Etapa 1 -->
  <div *ngIf="currentStep === 1" class="first-step">
    <div class="logoCe">
      <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6">
    </div>

    <h2 class="dialog-title">{{ title }}</h2>
    <div class="dialog-description">
      <p>Agora você pode conferir pelo nosso painel os <strong>Seus Recebíveis</strong> e o seu <strong>Fluxo de Caixa!</strong></p>
    </div>
    
    <button class="open-finance-btn"  (click)="closeModal()">Quero ver meus dados</button>
  </div>
</mat-dialog-content>
