<div style="align-items: center; display: flex; justify-content: center; flex-direction: column;">

    <h1 color='primary' style="padding: 10px; color: #008348;">
        Aguarde, estamos concluindo a integração!
    </h1>


    <mat-spinner [diameter]="120" color='primary'></mat-spinner>


</div>