<div style="display: flex; justify-content: flex-end; margin-bottom: 4px">
        <button mat-button (click)="onClose()"><mat-icon>close</mat-icon></button>
      </div> 
    <mat-dialog-content>    
       
<h1 style="font-size: 24px;
    margin-left: 21px;">Cadastrar Novo Item
   
</h1>
  
  <form [formGroup]="createFormGroup" style="padding: 20px;" >

<div class="form-container">
            <div class="edit-form" >
                <mat-label class="label-title">Nome do card de carrossel</mat-label>
                <input  matInput placeholder="Nome" class="input" formControlName="nameCtrl" />
            </div>
            
            <div class="form-field">
                <mat-select  class="select" placeholder="Painéis em que será visualizado"  formControlName="visiblePanelCtrl"  multiple>
                    <span style="margin-left: 16px; font-weight: 600;"> Visualizar painel de:</span>
                    <mat-option value="noTouch"> No Touch </mat-option>
                    <mat-option value="yesTouch"> Yes Touch </mat-option>
                    <mat-option value="clienteBaby">  Cliente Baby </mat-option>
                </mat-select>
            </div>
        </div>
  
        <h2 style="margin-top: 19px;
    margin-bottom: 23px;" >Configure o carrossel <span style="color: #008348;"> para o cliente:</span></h2>
      
        <div class="row">
            <div class="col-md-6">
                <label class="label-title">Imagem Logotipo</label>
                <app-customer-panel-logo-file-upload  [maximumDocuments]="1" [hasEmissionDate]="false"></app-customer-panel-logo-file-upload>

                <div *ngIf="this.createFormGroup.controls.logoCtrl.value">
                    <img [src]="this.createFormGroup.controls.logoCtrl.value"  class="appDropZone">
                  </div>
                <div *ngIf="this.createFormGroup.controls.logoCtrl.value" class="d-flex ">
                    <span style="margin-right: 56px;" class="span">
                        <mat-icon >delete_outline</mat-icon>
                    </span>
                </div>

                <div class="edit-form" >
                    <mat-label class="label-title">Título do Card</mat-label>
                    <input nput matInput  class="input" formControlName="textCtrl" required />
                </div>

                <div class="edit-form-custom " >
                    <mat-label class="label-title">Descrição do Card</mat-label>
                    
                    <textarea
                    class="input"
                    matInput
                    placeholder="Descrição"
                    formControlName="descricaoCtrl"
                    required
                    name="description"
                    matTextareaAutosize
                    matAutosizeMinRows="1"
                    ></textarea>
                </div>

                <div class="edit-form" >
                    <mat-label class="label-title">Botão de redirecionamento final*</mat-label>
                    <input nput matInput placeholder="URL do botão" class="input" formControlName="urlCtrl" required />
                </div>

                <div style="margin-top:17px ;">
                    <mat-label class="label-title">Clientes com os Regimes Tributários:</mat-label>
                    <mat-select  class="select-custom" placeholder="Regimes Tributários:"  multiple formControlName="taxRegimesCtrl">
                        <mat-option value="simples"> Simples Nacional</mat-option>
                        <mat-option value="lucro-presumido"> Lucro Presumido </mat-option>
                        <mat-option value="lucro-real">  Lucro Real </mat-option>
                        <mat-option value="mei">  MEI</mat-option>
                    </mat-select>
                </div>

            
            </div>

            <div class="col-md-6">
                <div class="form-field-custom">
                    <label class="label-title">Clientes com as Garantias:</label>
                    <mat-select  class="select-custom" placeholder="Garantias"  multiple formControlName="guaranteesCtrl">
                        <mat-option value="imoveis"> Imóveis</mat-option>
                        <mat-option value="veiculos"> Veículos </mat-option>
                        <mat-option value="recebiveis"> Recebíveis</mat-option>
                        <mat-option value="aval"> Aval</mat-option>
                    </mat-select>
                </div>

                <div class="form-field-custom">
                    <label style="margin-top: 18px;"  class="label-title">Clientes com as Finalidades:</label>
                    <mat-select  class="select-custom" placeholder="Finalidade"  multiple  formControlName="purposeCtrl">
                        <mat-option value="estoque"> Compra de Estoque</mat-option>
                        <mat-option value="capital-giro"> Capital de Giro </mat-option>
                        <mat-option value="equipamento"> Compra de Equip.</mat-option>
                        
                    </mat-select>
                </div>

                <div class="faixa-preco-container">
                    <label class="label-title">Clientes com Faixas de Faturamento em</label>
                    <button class="botao-adicionar" (click)="addRange()">+</button>
                </div>
                
                <div *ngFor="let faixa of faixaFaturamento; let i = index" class="faixa-container">
                    <label>De</label>
                    <mat-form-field>
                        <input   matInput class="input-preco" formControlName="revenueMinCtrl" />
                    </mat-form-field>
                
                    <label>A</label>
                    <mat-form-field>
                        <input matInput  class="input-preco" formControlName="revenueMaxCtrl" />
                    </mat-form-field>
                    <button  class="botao-remover">-</button>
                </div>
                  

                <div class="form-field-custom">
                    <label style="margin-top: 18px;"  class="label-title">Tipo:</label>
                    <mat-select  class="select-custom" placeholder="Painéis em que será visualizado"  formControlName="tipoCtrl">
                        <mat-option value="service"> Benefícios exclusivos</mat-option>
                        <mat-option value="faq"> Agregador de dados </mat-option>
                    </mat-select>
                </div>

            </div>
        </div> 
     
        </form>
        <div style="display: flex; justify-content: center; margin-bottom: 4px">
            <button mat-button (click)="onSaveData()" class="btn-save">Salvar</button >
        </div>
       
    </mat-dialog-content>
    

