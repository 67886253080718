import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../../../../functions/src/models/Customer';
import { CustomerService } from '../../services/customer.service';
import { ErpsService } from '../../services/erps.service';

@Component({
  selector: 'app-erps-confirm-page',
  templateUrl: './erps-confirm-page.component.html',
  styleUrls: ['./erps-confirm-page.component.scss']
})
export class ErpsConfirmPageComponent implements OnInit {
  customer: Customer;

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private erpsService: ErpsService
  ){}

  async ngOnInit(): Promise<void> {
    const authorizationCode = this.route.snapshot.queryParams['token'];
    const customerUid = this.route.snapshot.queryParams['uid'];
  
    if (authorizationCode && customerUid) {
      try {
        // Obter o cliente pelo UID
        this.customer = await this.customerService.getCustomerByUID(customerUid);
  
        // Obter o accessToken com o authorizationCode
        const accessToken = await this.erpsService.getTokenContaAzul(authorizationCode);
  
        // Passar o accessToken para o próximo serviço
        await this.erpsService.getDataContaAzul(accessToken, customerUid);
      } catch (error) {
        console.error('Erro ao inicializar:', error);
      }
    }
  }
}
 

