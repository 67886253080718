import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Customer } from '../../../../functions/src/models/Customer';
import { CarouselCardsEntity } from '../../../../functions/src/models/general/carousel-cards.entity';
import { CarrosselService } from '../../customer/services/carrossel.service';
import { CarouselPreviewComponent } from '../carousel-preview/carousel-preview.component';
import { CustomerPanelNewCardComponent } from '../customer-panel-new-card/customer-panel-new-card.component';


@Component({
  selector: 'app-customer-panel-configuration',
  templateUrl: './customer-panel-configuration.component.html',
  styleUrls: ['./customer-panel-configuration.component.scss']
})
export class CustomerPanelConfigurationComponent implements OnInit {
  item: CarouselCardsEntity[];

  @Input() customer: Customer;
  

  constructor(private carrosselService: CarrosselService, public dialog: MatDialog) {  this.dataSourceCardbenefits = new MatTableDataSource<CarouselCardsEntity>([]), this.dataSourceCardDatas = new MatTableDataSource<CarouselCardsEntity>([]) }

  dataSourceCardbenefits: MatTableDataSource<CarouselCardsEntity>;
  dataSourceCardDatas: MatTableDataSource<CarouselCardsEntity>;

  carouselCardsEntity: CarouselCardsEntity[] = [];

  displayedColumns: string[] = ['item', 'edit', 'preview', 'ativo'];

  searchCardbenefit: string = '';
  searchCardData: string = '';

  ngOnInit(): void {
  
    this.carrosselService.getItemsAll().then(data => {
   
     const cardbenefits = data.filter(item => item.type === 'service');
     const cardDatas = data.filter(item => item.type === 'faq');
    
       this.dataSourceCardbenefits.data = cardbenefits;
       this.dataSourceCardDatas.data = cardDatas;
     
    
    });
   

  }

  onInputCardBenefit() {

    if (!this.searchCardbenefit.trim()) {
      this.dataSourceCardbenefits.filter = '';
     
    }
  }

  onInputCardData() {

    if (!this.searchCardData.trim()) {
      this.dataSourceCardDatas.filter = '';
     
    }
  }

  applyFilter() {
    this.dataSourceCardbenefits.filter = this.searchCardbenefit.trim().toLowerCase();
    this.dataSourceCardDatas.filter = this.searchCardData.trim().toLowerCase();
  }

  clearFilter() {
    this.searchCardbenefit = '';
    this.searchCardData = '';
    this.dataSourceCardbenefits.filter = ''; 
    this.dataSourceCardDatas.filter = ''

  }

  openNewItemModal(): void {
    const dialogRef = this.dialog.open(CustomerPanelNewCardComponent, {
      width: '737px', 
      height: '736px',
      panelClass: 'custom-modal'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        console.log('Novo item cadastrado:', result);
      }
    });
  }


  openCardModal(item : any): void { 
    if (!item ) {
      console.warn('Nenhum dado foi passado para o modal.');
      return;
    }
  
    const dialogRef = this.dialog.open(CarouselPreviewComponent, {
      data: item , 
      width: '320px',
      height: '350px',
    });
  

    dialogRef.afterClosed().subscribe(result => {
    
     
    });
  
  }

  onEditData(element: any): void {
    const dialogRef = this.dialog.open(CustomerPanelNewCardComponent, {
      width: '737px', 
      height: '997px',
      data: element, 
      
    });
   

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Dados salvos:', result); 
      }
    });
  }

  onStatusChange(cg: any): void {
  
    const newStatus = cg.ativo;
    this.carrosselService.seveStatus(cg.id, newStatus) 
      
  }
}
