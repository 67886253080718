<mat-dialog-content class="dialog-container">
  <!-- Etapa 1 -->
  <div *ngIf="currentStep === 'init'" class="first-step">
    <span class="close ml-4" (click)="closeModal()">&times;</span>

    <div class="logoCe">
      <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6">
    </div>

    <h2 class="dialog-title">Agilize seu crédito conectando seus ERP'S!</h2>
    <div class="dialog-description">
      <p>Fornecer os dados ajuda na análise de crédito e transparência!</p>
      <p><strong>Segurança</strong></p>
      <p>Nossa conexão é segura e segue os mais altos padrões do mercado.</p>
      <p><strong>Privacidade</strong></p>
      <p>Suas credenciais de acesso <strong>nunca</strong> serão compartilhadas com terceiros.</p>
    </div>
    <button class="open-finance-btn"  (click)="goToNextStep('select')">Continuar</button>
  </div>

  <!-- Etapa 2 -->
  <div *ngIf="currentStep === 'select'" class="second-step">

     <span class="return" (click)="goToNextStep('init')"><</span> 

    
    <div class="logoCe">
      <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6">
    </div>

    <h2>Selecione para conectar</h2>
    <div class="options">
      <!-- Opções dinâmicas dos ERPs -->
      <div *ngFor="let erp of erpsList" class="option" (click)="goRedirect(erp.mnemonico, erp.urlRedirect)">
        <div class="logo">
          <img [src]="erp.logo" alt="{{ erp.name }}" />
        </div>
        <p style="margin-left: 10px;">{{ erp.name }}</p>
      </div>
    </div>
    <p style="margin-top: 10px;">Em breve novas opções</p>
  </div>

  <!-- Etapa 3 -->
  <div *ngIf="currentStep === 'omie'" class="third-step">
    <span class="return" (click)="goToNextStep('select')"><</span>

    <div class="logoCe">
      <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2Fnewlogo.svg?alt=media&token=670d09c9-9b73-453f-9598-cce88c9486e6">
    </div>

    <h2>Faça login na sua conta</h2>
    <div class="login-form">
      <div class="optionSelect">
        <div class="logo" >
          <img src="https://firebasestorage.googleapis.com/v0/b/capital-empreendedor.appspot.com/o/assets%2Fimages%2FOmie.png?alt=media&token=f87b743d-cbe4-4e53-9cc7-a40e93c59ddf"/>
        </div>
        <p style="margin-left: 10px;">Omie</p>
      </div>
      <div class="input-group">
        <input type="text" placeholder="App Key" [(ngModel)]="appKey" />
      </div>
      <div class="input-group">
        <input type="password" placeholder="App Secret" [(ngModel)]="appSecret" />
      </div>
      <div class="checkbox-group">
        <input type="checkbox" id="authorize" [(ngModel)]="isAuthorized" />
        <label for="authorize">Autorizo a Capital Empreendedor a buscar meus dados.</label>
      </div>
      <button class="open-finance-btn"  (click)="login()">Logar</button>
    </div>
  </div>
</mat-dialog-content>
