/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Contract } from 'functions/src/models/Contract';
import { Customer } from 'functions/src/models/Customer';
import { User, UserRole } from 'functions/src/models/User';
import { Customer as CustomerMongoDB } from 'functions/src/models/customers/Customer';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { leftJoinDocument } from 'src/app/core/handler/collectionJoin';
import { UserHistory } from 'src/app/core/models/model-interfaces';
import { Document, UserDocuments } from '../../../../functions/src/models/UserDocument';
import { Document as DocumentMongoDB } from '../../../../functions/src/models/documents/UserDocument';
import { environment } from '../../../environments/environment';
import { CreditoPreAprovado, InstituicaoFinanceira } from '../instituicao-financeira/instituicao-financeira';
import { InstFinancDocuments } from '../user-management/user-management';
import { UserConsent } from './../../../../functions/src/models/User';
import { TipoDocumento } from './../../../../functions/src/models/model-interface';
import { NotesService } from './notes.service';
import { OpportunityManagementService } from './opportunity-management.service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  userscollection: AngularFirestoreCollection<User>;
  users: Observable<User[]>;
  ref: AngularFirestoreDocument<User>;

  instituicaoFinanceiraCollection: AngularFirestoreCollection<InstituicaoFinanceira>;
  instituicaoFinanceira: Observable<InstituicaoFinanceira[]>;
  instituicaoFinanceiraDoc: AngularFirestoreDocument<InstituicaoFinanceira>;

  constructor(
    private angularFirestore: AngularFirestore,
    private oppService: OpportunityManagementService,
    private http: HttpClient,
    private logger: LogService,
    private authService: AuthService,
    private notesService: NotesService
  ) {
    this.logger.controllerName = 'UserManagementService';
    this.userscollection = this.angularFirestore.collection('users', (x) => x.orderBy('nome', 'asc'));
    this.instituicaoFinanceiraCollection = this.angularFirestore.collection('instituicoes-financeiras', (x) =>
      x.where('ativo.id', '==', 'sim').orderBy('nome', 'asc')
    );
  }

  getAllUsers() {
    return this.angularFirestore.collection('users').valueChanges();
  }

  getAllAdminUsers() {
    return this.angularFirestore.collection<User>('users', (x) => x.where('mainRole', '==', 'admin')).get();
  }

  getUserByUid(uid: string): Observable<User> {
    return this.angularFirestore.collection('users').doc<User>(uid).valueChanges();
  }

  getCustomersTeste() {
    return this.angularFirestore.collection<User>('customers', (x) => x.where('creditValue', '>=', 300000000)).get();
  }

  getUsers() {
    return this.angularFirestore
      .collection<User>('users', (x) => x.orderBy('nome', 'asc'))
      .valueChanges();
  }

  getUsersData() {
    return this.angularFirestore
      .collection<User>('users', (x) => x.orderBy('nome', 'asc').limit(2))
      .valueChanges()
      .pipe(
        switchMap((dadosUsuarios) => {
          const users = dadosUsuarios.map((u) => u);
          return combineLatest([
            of(dadosUsuarios),
            combineLatest([
              users.map((user) => {
                return this.angularFirestore.doc<UserDocuments>(`user-documents/${ user.email }`).valueChanges();
              }),
            ]),
          ]);
        }),
        map(([dadosUsuarios, documentos]) => {
          return dadosUsuarios.map((dadosUsuario) => {
            // dadosUsuario.documentos = documentos
            return dadosUsuario;
          });
        })
      );
  }

  // You need to return the doc to get the current cursor.
  getCollection(ref, queryFn?): Observable<any[]> {
    return this.angularFirestore
      .collection<User>(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          console.log('quantidade de usuários: ', actions.length);
          return actions.map((a) => {
            const data = a.payload.doc.data();
            return data;
          });
        })
      );
  }

  getTotalUsuarios() {
    return this.angularFirestore
      .collection('users')
      .snapshotChanges()
      .pipe(
        map((c) => c.length),
        shareReplay(1)
      );
  }

  getUsersDataJoin(
    fieldSort: string,
    sortDirection: string,
    fieldValueFirst: any,
    fieldValueFirstPrev: any,
    fieldValueLast: any,
    pageSize: number,
    actionEvent: string,
    filtro: string
  ): Observable<any> {
    if (actionEvent === 'nextPage') {
      return this.angularFirestore
        .collection<User>('users', (ref) =>
          ref
            .orderBy(fieldSort, sortDirection === 'asc' ? 'asc' : 'desc')
            .limit(pageSize)
            .startAfter(fieldValueLast[fieldSort])
        )
        .valueChanges()
        .pipe(leftJoinDocument(this.angularFirestore, 'email', 'user-documents', 'documentos'), shareReplay(1));
    } else if (actionEvent === 'prevPage') {
      return this.angularFirestore
        .collection<User>('users', (ref) =>
          ref
            .orderBy(fieldSort, sortDirection === 'asc' ? 'asc' : 'desc')
            .limit(pageSize)
            .startAt(fieldValueFirst[fieldSort])
            .endBefore(fieldValueFirstPrev[fieldSort])
        )
        .valueChanges()
        .pipe(leftJoinDocument(this.angularFirestore, 'email', 'user-documents', 'documentos'), shareReplay(1));
    } else {
      if (filtro) {
        return this.angularFirestore
          .collection<User>('users', (ref) =>
            ref
              .orderBy(fieldSort, sortDirection === 'asc' ? 'asc' : 'desc')
              .limit(pageSize)
              .startAt(filtro)
              .endAt(filtro + '\uf8ff')
          )
          .valueChanges()
          .pipe(leftJoinDocument(this.angularFirestore, 'email', 'user-documents', 'documentos'), shareReplay(1));
      } else {
        return this.angularFirestore
          .collection<User>('users', (ref) =>
            ref.orderBy(fieldSort, sortDirection === 'asc' ? 'asc' : 'desc').limit(pageSize)
          )
          .valueChanges()
          .pipe(leftJoinDocument(this.angularFirestore, 'email', 'user-documents', 'documentos'), shareReplay(1));
      }
    }
  }

  getUsersByFilter(start: Subject<string>): Observable<any[]> {
    return start.pipe(
      switchMap((offset) => {
        return this.angularFirestore
          .collection<any>('users', (ref) => ref.orderBy(`email`).startAt(offset.toLowerCase()).limit(5))
          .valueChanges();
      })
    );
  }

  getUsersDataServer(sort: string, sortDirection: string, pageIndex: number, pageSize: number): Observable<any> {
    console.log('chamou get users data server');
    return this.http.post(
      `${ environment.functionsUrl }/usermanagement/get-users-data`,
      { sort: sort, sortDirection: sortDirection, pageIndex: pageIndex, pageSize: pageSize },
      { headers: this.authService.getHeader() }
    );
  }

  getAllUsersDocuments() {
    return this.angularFirestore.collection<UserDocuments>(`user-documents`).valueChanges();
  }

  getUserHistory(userEmail: string) {
    return this.angularFirestore.doc<UserHistory>(`user-history/${ userEmail }`).valueChanges();
  }

  getCustomer(uid: string) {
    return this.angularFirestore.doc<Customer>(`customers/${ uid }`).valueChanges();
  }

  getCustomersByAgent(agent) {
    const agentUsers = this.angularFirestore.collection('customers', (x) =>
      x.where('agent', '==', agent).orderBy('name', 'asc')
    );
    return agentUsers.valueChanges();
  }

  async updateUser(userData: User) {
    this.ref = this.angularFirestore.doc(`users/${ userData.email }`);
    try {
      const s = await this.ref.update(userData);
      this.logger.info('usuário alterado com sucesso.');
      return s;
    } catch (e) {
      this.logger.error('Problemas na alteração de usuário.', e);
    }
  }

  updateUserFb(uid: string, data: User = {}): Promise<void> {
    return new Promise((resolve, reject) => {
      if (uid) {
        const userRef: AngularFirestoreDocument<User> = this.angularFirestore.doc(`users/${ uid }`);

        userRef
          .set(
            {
              ...data,
            },
            { merge: true }
          )
          .then(() => {
            console.log('User data updated on database');
            resolve();
          })
          .catch((err) => {
            console.error('Error getting User data', err);
            reject(err);
          });
      } else {
        reject(new Error('Missing User uid'));
      }
    });
  }

  async updateCustomer(customer: Customer) {
    this.ref = this.angularFirestore.doc(`customers/${ customer.uid }`);
    try {
      const s = await this.ref.update(customer);
      this.logger.info('Customer alterado com sucesso.');
      return s;
    } catch (e) {
      this.logger.error('Problemas na alteração de customer.', e);
    }
  }

  getTiposDocumentos(userDocuments: UserDocuments, userEmail: string) {
    let count = 1;
    const tiposDocumentosCollection = this.angularFirestore.collection('tipo-documento', (x) =>
      x.where('ativo.id', '==', 'sim').where('automatico.id', '==', 'sim').orderBy('nome', 'asc')
    );
    const tipoDocumento = tiposDocumentosCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as Document;
          data.id = a.payload.doc.id;
          data.situacao = 'Pendente';
          data.qtdArquivosEsperados = 1;
          data.qtdArquviosSubmetidos = 0;
          data.email = userEmail;
          data.ordenacao = count++;
          if (data.anoExercicio.id === 'sim') {
            data.qtdArquivosEsperados = data.anoExercicioQtd;
          }
          if (userDocuments && userDocuments.documentos) {
            const userData = userDocuments.documentos.find((f) => f.mnemonico === data.mnemonico);
            if (userData) {
              data.situacao = userData.situacao;
              data.qtdArquviosSubmetidos = userData.qtdArquviosSubmetidos;
              data.arquivosInfo = userData.arquivosInfo;
              data.dataAprovacao = userData.dataAprovacao;
              data.motivoReprovacao = userData.motivoReprovacao;
              data.controleVencimentoId = userData.controleVencimentoId;
              data.ordenacao = userData.ordenacao;
            }
          }
          return data;
        });
      })
    );
    return tipoDocumento;
  }

  sendEmailVerification(email: string, displayName: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/usermanagement/send-email-verification`,
          {
            email: email,
            displayName: displayName,
            redirect: `${ environment.baseURL }/customer/login`,
          },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log(`Customer email verification sended`);
          resolve();
        })
        .catch((err) => {
          console.error('Error sending email verification', err);
          reject('error-sending-new-email');
        });
    });
  }

  setDocumentoReprovado(dadosTipoDocumento: Document) {
    const ref = this.angularFirestore.collection('user-documents-disapproved');
    ref
      .add(dadosTipoDocumento)
      .then(() => {
        this.logger.info('Documento reprovado incluído com sucesso');
      })
      .catch((e) => {
        this.logger.error('Houve um problema na inclusão de um documento reprovado.', e);
      });
  }

  deleteControleVencimento(docId: string) {
    return this.angularFirestore.collection('user-doc-controle-vencimento').doc(docId).delete();
  }

  getTiposDocumentosAtivos(): Observable<TipoDocumento[]> {
    const tiposDocumentsRef = this.angularFirestore.collection('tipo-documento', (x) =>
      x.where('ativo.id', '==', 'sim').orderBy('nome', 'asc')
    );
    return tiposDocumentsRef.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as TipoDocumento;
          data.id = a.payload.doc.id;
          return data;
        });
      })
    );
  }

  getTipoDocumentoByMnemonic(mnemonic: string): Observable<TipoDocumento> {
    const tiposDocumentsRef = this.angularFirestore.collection('tipo-documento', (x) =>
      x.where('mnemonico', '==', mnemonic).orderBy('nome', 'asc')
    );
    return tiposDocumentsRef.get().pipe(
      map((snap) => {
        const data = snap.docs[0]?.data() as TipoDocumento || null;
        if (data) {
          data.id = snap?.docs[0]?.id || null;
        }
        return data;
      })
    );
  }

  sendEmailAddNewDocument(documentos) {
    this.http
      .post(`${ environment.functionsUrl }/document/send-email-new-doc`, documentos, {
        responseType: 'text',
        headers: this.authService.getHeader(),
      })
      .toPromise()
      .then((result) => {
        this.logger.info('Notificação de e-mail enviada - new doc - document/send-email-new-doc', result);
      })
      .catch((e) => {
        this.logger.error(
          'Problemas ao enviar a notificação de novo documento por e-mail',
          e,
          'document/send-email-new-doc'
        );
      });
  }

  getInstituicoesFinanceirasAtivas() {
    const creditosPreAprovados = this.instituicaoFinanceiraCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data() as InstituicaoFinanceira;
          data.id = a.payload.doc.id;
          const credito: CreditoPreAprovado = {
            id: 0,
            idProposta: null,
            idSalesforce: null,
            faseOportunidade: this.oppService.getOppStageFromNumber(data.defaultInitialStage),
            nome: data.nome,
            nomeNoSistema: data.nomeNoSistema,
            valor: '',
            modalidade: data.modalidade,
            modalidadesStr: data.modalidade.map((o) => o.nome).join(', '),
            prazo: data.prazo,
            garantia: data.garantias.map((o) => o.nome).join(', '),
            limiteCredito: 0,
            limiteOperacao: null,
            limiteOperacaoStr: null,
            instituicaoFinanceiraId: data.id,
            linhaAdicional: false,
            linhaAtiva: false,
            linhaFechada: false,
            taxa: null,
            situacao: null,
            motivoCancelamento: null,
            descricao: null,
            contaBancariaSalesforce: data.contaBancariaSalesforce,
          };
          return credito;
        });
      })
    );
    return creditosPreAprovados;
  }

  getDocumentosInstituicaoFinanceira(instituicaoFinanceiraId) {
    return this.angularFirestore
      .doc<InstFinancDocuments>(`inst-financ-documentos/${ instituicaoFinanceiraId }`)
      .valueChanges();
  }

  getDadosContratoUsuario(uid: string) {
    return this.angularFirestore.doc(`contract/${ uid }`).get();
  }

  getCustomerByCNPJ(userCNPJ: string) {
    return this.angularFirestore.collection(`customers`, (x) => x.where('cnpj', '==', userCNPJ)).get();
  }

  async updateDadosContrato(contract: Contract) {
    const userCtt = this.angularFirestore.doc(`contract/${ contract.uid }`);
    try {
      const s = await userCtt.set(contract, { merge: true });
      this.logger.info('dados do contrato alterado com sucesso.');
      return s;
    } catch (e) {
      this.logger.error('Problemas na alteração dos dados do contrato.', e);
    }
  }

  async resendContract(contract: Contract, recipient: { name: string; email: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/email/contract`,
          { contract, recipient },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Contract was sent');
          resolve();
        })
        .catch((err) => {
          console.error('Error sending contract', err);
          reject(err);
        });
    });
  }

  async sendEmailSolicitacaoCadastro(dadosSolicitacao) {
    return await this.http
      .post(`${ environment.functionsUrl }/usermanagement/send-email-solicitacao-cadastro`, dadosSolicitacao, {
        responseType: 'text',
        headers: this.authService.getHeader(),
      })
      .toPromise()
      .then(() => {
        this.logger.info('E-mail enviado com sucesso - contrato/send-email-solicitacao-cadastro');
      })
      .catch((error) => {
        this.logger.error(
          'Problemas ao reenviar um e-mail solicitação de cadastro ao site.',
          error,
          'usermanagement/send-email-solicitacao-cadastro'
        );
        throw error;
      });
  }

  async sendEmailLimiteOperacao(dadosEmail) {
    return await this.http
      .post(`${ environment.functionsUrl }/usermanagement/send-email-limite-maior-pre-aprovado`, dadosEmail, {
        responseType: 'text',
        headers: this.authService.getHeader(),
      })
      .toPromise()
      .then(() => {
        this.logger.info('E-mail enviado com sucesso - contrato/send-email-limite-maior-pre-aprovado');
      })
      .catch((error) => {
        this.logger.error(
          'Problemas ao enviar um e-mail solicitação limite maio pre aprovado.',
          error,
          'send-email-limite-maior-pre-aprovado'
        );
        throw error;
      });
  }

  async sendEmailMensagemEnviadaPorAdm(dadosEmail) {
    return await this.http
      .post(`${ environment.functionsUrl }/usermanagement/send-email-mensagem-enviada-por-adm`, {
        responseType: 'text',
        headers: this.authService.getHeader(),
      })
      .toPromise()
      .then(() => {
        this.logger.info('E-mail enviado com sucesso - usermanagement/send-email-mensagem-enviada-por-adm');
      })
      .catch((e) => {
        this.logger.error(
          'Problemas ao enviar um e-mail de nova mensagem enviada pelo admin.',
          e,
          'send-email-mensagem-enviada-por-adm'
        );
        throw e;
      });
  }

  async updateUserBackend(customer: Customer, contract: Contract, hasUpdateTerm: boolean) {
    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/update-user-data`,
        { customer, contract, hasUpdateTerm },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise()
      .catch((error) => {
        console.error(
          'Problemas ao enviar um e-mail de nova mensagem enviada pelo admin.',
          error,
          'send-email-mensagem-enviada-por-adm'
        );
        throw error;
      });
  }

  async alterarFaseOportunidade(
    uid: string,
    linhaCreditoAlterada: CreditoPreAprovado,
    reabertura = false,
    updateProposal = false
  ) {
    console.log('Requesting to change opportunity stage: ', uid, linhaCreditoAlterada.nomeNoSistema);

    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/change-credit-line-stage`,
        { uid, linhaCreditoAlterada, reabertura, updateProposal },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise()
      .then((response) => console.log(response))
      .catch((error) => {
        this.logger.error('Problemas ao tentar alterar a fase de uma linha de crédito.', error);
        throw error;
      });
  }

  async alterarDetalhesLinhaCredito(customerInfos: any, linhaCreditoAlterada: CreditoPreAprovado) {
    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/update-credit-line-detail`,
        { userInfos: customerInfos, linhaCreditoAlterada: linhaCreditoAlterada },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise()
      .catch((error) => {
        this.logger.error('Problemas ao tentar alterar os detalhes da linha de crédito.', error);
        throw error;
      });
  }

  async aprovarDocumentosUsuario(uid: string, approvedDocument: DocumentMongoDB) {
    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/document-approve-user`,
        { uid, approvedDocument },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise()
      .catch((error) => {
        this.logger.error('Problemas ao tentar alterar os detalhes da linha de crédito.', error);
        throw error;
      });
  }

  async adicionarNovasLinhasCredito(customer: Customer, listCreditoPreAprovado: CreditoPreAprovado[]) {
    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/add-new-credit-lines`,
        { uid: customer.uid, creditosPre: listCreditoPreAprovado },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise()
      .catch((error) => {
        this.logger.error('Problemas ao tentar adicionar novas linhas de créditos.', error);
        throw error;
      });
  }

  getDetalhesBanco(idBanco: string) {
    return this.angularFirestore.doc(`instituicoes-financeiras/${ idBanco }`).get();
  }

  // Receive user e-mail and agent uid
  insertAgentOfUser(customer: string, agent: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection(`agents`)
        .doc(agent)
        .get()
        .toPromise()
        .then((a) => {
          if (a.exists && a.data().createdAt) {
            this.angularFirestore
              .collection(`customers`)
              .doc(customer)
              .set(
                {
                  agent,
                },
                { merge: true }
              )
              .then(() => {
                this.http
                  .post(
                    `${ environment.functionsUrl }/agent/create-partner`,
                    {
                      customer,
                      redirectLink: `${ environment.baseURL }/agents/lead/${ customer }`,
                      wasSettedByAdmin: true,
                    },
                    { responseType: 'json', headers: this.authService.getHeader() }
                  )
                  .toPromise()
                  .then((res) => {
                    resolve(res);
                  })
                  .catch((err) => {
                    console.error('Error creating partner', err);
                    reject(err);
                  });
              })
              .catch((err) => {
                console.error(`Error saving agent on user ${ customer }`, err);
                reject(err);
              });
          } else {
            console.error(`User ${ agent } not found or not an agent`);
            reject('invalid-agent');
          }
        })
        .catch((err) => {
          console.error(`Error verifying if agent ${ agent } exists`, err);
          reject(err);
        });
    });
  }

  // Receive user e-mail
  removeAgentFromUser(customer: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/agent/delete-partner`,
          { customer },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error('Error deleting', err);
          reject(err);
        });
    });
  }

  changeAgentOfUser(customer: string, agent: string): Promise<void | any> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .collection(`agents`)
        .doc(agent)
        .get()
        .toPromise()
        .then((a) => {
          if (a.exists && a.data().createdAt) {
            this.removeAgentFromUser(customer)
              .then((resDel) => {
                this.insertAgentOfUser(customer, agent)
                  .then((resIns) => {
                    console.log('Agent changed succesfully');
                    resolve(resDel || resIns || null);
                  })
                  .catch((err) => {
                    console.error('Previous agent removed, but new agent not saved', err);
                    reject('only-removed');
                  });
              })
              .catch((err) => {
                console.error('Error removing previous agent', err);
                reject(err);
              });
          } else {
            console.error(`User ${ agent } not found or not an agent`);
            reject('invalid-agent');
          }
        })
        .catch((err) => {
          console.error(`Error verifying if agent ${ agent } exists`, err);
          reject(err);
        });
    });
  }

  getNegativeDataFromSerasa(customer: string, admin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/serasa/negative-data-report`,
          { user: customer, admin },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error('Error getting general user extra data', err);
          reject(err);
        });
    });
  }

  getScoreDataFromSerasa(customer: string, admin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/serasa/top-score-pj-data-report`,
          { user: customer, admin },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error('Error getting general user extra data', err);
          reject(err);
        });
    });
  }


  getCustomerDataFromSerasa(cnpj: string) {
    return this.angularFirestore.collection('serasa-negative-data-history',
      ref => ref.where('cnpj', '==', cnpj).orderBy('date', 'desc').limit(1)).get().pipe(
        map(querySnapshot => {
          return querySnapshot.docs.map(doc => doc.data());
        })
      );
  }

  getGeneralDataFromIntegration(user: { uid: string; cnpj: string }, admin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/bigdatacorp/general`,
          { user, admin },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error('Error getting general user extra data', err);
          reject(err);
        });
    });
  }

  getBoaVistaDataFromIntegration(customer: string, admin: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/bigdatacorp/boa-vista`,
          { user: customer, admin },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error('Error getting general user extra data', err);
          reject(err);
        });
    });
  }

  searchCustomers({ filterField, filterValue, orderField, orderDirection, pageSize, page }): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const params = `filterField=${ filterField }&filterValue=${ filterValue.replaceAll('&', '||').replaceAll('@', '||') }&orderField=${ orderField }&orderDirection=${ orderDirection }&page=${ page }&pageSize=${ pageSize }`;

      console.log('params user-management', params);

      this.http
        .get(`${ environment.functionsUrl }/customer?${ params }`, {
          responseType: 'json',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then((res) => {
          resolve(res as any[]);
        })
        .catch((err) => {
          console.error('Error getting customers.', err);
          reject(err);
        });
    });
  }

  integrateCreditas(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('creditas', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('Creditas - Error processing integration', err);
              if ([409, 422].includes(err.status)) {
                reject('duplicated-lead');
              } else {
                reject(err);
              }
            });
        })
        .catch((err) => {
          console.error('Creditas - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  integrateCredihome(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('credihome', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('Credihome - Error processing integration', err);
              if ([409, 422].includes(err.status)) {
                reject('duplicated-lead');
              } else {
                reject(err);
              }
            });
        })
        .catch((err) => {
          console.error('Credihome - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  integrateInstitution(
    institution: string,
    customer: Customer | string,
    endpoint = '/credit/process-credit-institution'
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }${ endpoint }`,
          { customer, institution },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.error('Error processing integration', institution, err);
          reject(err);
        });
    });
  }

  integrateMoneyMoney(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('money_money_invest', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('QFlash - Error processing integration', err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error('QFlash - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  integrateQFlash(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('q_flash', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('QFlash - Error processing integration', err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error('QFlash - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  // TODO: start progress to all institutions use this one
  integrateCreditInstitution(
    customer: Customer,
    nomeNoSistema: string,
    endpoint = '/credit/process-credit-institution'
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution(nomeNoSistema, customer, endpoint)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('Error credit processing integration', err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error('Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  checkCreditInstitutionStatus(customer: Customer, institution: string): Promise<void | any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/credit/verify-credit-institution-status`,
          { customer, institution },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((data) => {
          // console.log('Return from Backend Institution API',data);
          resolve(data);
        })
        .catch((err) => {
          console.error('Error processing integration', institution, err);
          reject(err);
        });
    });
  }

  integrateBCredi(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('bcredi', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('BCredi - Error processing integration', err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error('Bcredi - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  integrateIouu(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('iouu', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('Iouu - Error processing integration', err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error('Iouu - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  integrateAdianteSA(customer: Customer): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore
        .doc(`customers/${ customer.uid }`)
        .set({ additionalData: customer.additionalData }, { merge: true })
        .then(() => {
          this.integrateInstitution('adiante_sa', customer)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              console.error('Adiante SA - Error processing integration', err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error('Adiante SA - Error saving on database', err);
          reject('error-saving-on-firebase');
        });
    });
  }

  reprocessOpportunities(uid: string): Promise<CreditoPreAprovado[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/opportunity/reprocess-opportunities`,
          { uid },
          { responseType: 'json', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then((credit: CreditoPreAprovado[]) => {
          resolve(credit);
        })
        .catch((err) => {
          console.error('Error processing opportunities', uid, err);
          reject(err);
        });
    });
  }

  async reprocessDocuments(customer: CustomerMongoDB, documents: DocumentMongoDB[]): Promise<any> {
    return await this.http
      .post(
        `${ environment.functionsUrl }/usermanagement/reprocess-user-documents`,
        { customer, documents },
        { responseType: 'text', headers: this.authService.getHeader() }
      )
      .toPromise();
  }

  async signPrivacyPolicy(uid: string, privacyPolicyDate: number, mainRole: UserRole): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${ environment.functionsUrl }/usermanagement/${ uid }?agreed_privacy_policy=true`,
          { privacyPolicyDate, mainRole },
          {
            responseType: 'json',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then(() => {
          console.log('Signed Privacy Policy.');
          resolve();
        })
        .catch((err) => {
          console.error('Error signing Privacy Policy.', err);
          reject(err);
        });
    });
  }

  async signOrCancelCookiePolicy(
    uid: string,
    cookiePolicy: UserConsent['cookiePolicy'],
    mainRole: UserRole
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.angularFirestore.collection('users').doc(uid).update({
        'userConsent.consentGiven': true
      });
      this.http
        .put(
          `${ environment.functionsUrl }/usermanagement/${ uid }?agreed_cookie=${ cookiePolicy.agreedCookie }`,
          { mainRole, ...cookiePolicy },
          {
            responseType: 'json',
            headers: this.authService.getHeader(),
          }
        )
        .toPromise()
        .then(() => {
          console.log('Signed Privacy Policy.');
          resolve();
        })
        .catch((err) => {
          console.error('Error signing Privacy Policy.', err);
          reject(err);
        });
    });
  }

  changeUserEmail(
    uid: string,
    newEmail: string,
    previousEmail: string,
    loggedAdmin: string,
    userType: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${ environment.functionsUrl }/usermanagement/change-email`,
          {
            user: { uid, previousEmail, newEmail, userType },
            admin: loggedAdmin,
            redirect: `${ environment.baseURL }/${ userType }/login`,
          },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log(`${ userType } email updated`);
          resolve();
        })
        .catch((err) => {
          console.error('Error changing user email', err);
          reject('error-changing-password');
        });
    });
  }

  async deleteUser(uid: string, mainRole: UserRole, motivation: string, deletedBy: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${ environment.functionsUrl }/usermanagement/delete-user/${ uid }`, {
          params: {
            mainRole,
            motivation,
            deletedBy,
            redirectAdminTo: `${ environment.baseURL }/admin/clientes/detalhes/${ uid }`,
          },
          responseType: 'arraybuffer',
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then(() => {
          console.log('Usuario Deletado com Sucesso.');
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao tentar Deletar o Usuario.', err);
          reject(err);
        });
    });
  }

  // serviço para enviar os dados do pdf e do clinete para o back-end
  sendEmailCharge(doc: string, recipient: { name: string; email: string; companyName: string; ccOne?: string; ccTwo?: string; chargeLink?: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log(recipient.ccOne, recipient.ccTwo);
      this.http
        .post(
          `${ environment.functionsUrl }/email/charge`,
          { doc, recipient },
          { responseType: 'text', headers: this.authService.getHeader() }
        )
        .toPromise()
        .then(() => {
          console.log('Charge send');
          resolve();
        })
        .catch((err) => {
          console.error('Error sending email', err);
          reject(err);
        });
    });
  }

  requestCancellation(uid: string, cancelTerms: any, motivation: string, customer: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${ environment.functionsUrl }/customer/${ uid }`, {
          params: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            cancel_terms: cancelTerms,
            motivation: motivation,
            customer: customer
          }
        }, {
          headers: this.authService.getHeader(),
        })
        .toPromise()
        .then(() => {
          console.log('Solicitação de exclusão de conta feita com sucesso')
          resolve();
        })
        .catch((err) => {
          console.error('Erro ao solicitar exclusão de conta', err);
          reject(err);
        })
    })
  }
}
