import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { OpenFinanceService } from '../../../core/services/open-finance.service';
import { CustomerService } from '../../services/customer.service';
import { ErpsService } from '../../services/erps.service';

@Component({
  selector: 'app-customer-erps-dialog',
  templateUrl: './customer-erps-dialog.component.html',
  styleUrls: ['./customer-erps-dialog.component.scss']
})
export class CustomerErpsDialogComponent implements OnInit {

  @ViewChild('accessToken') accessTokenElm: ElementRef;

  isOpen = false;
  accessToken: string;
  urlFunctions: string;
  customerUid: string;
  customer: Customer;
  userSubscription: Subscription;
  erpsList: any[] = [];

  // Gerencia o estado do diálogo
  currentStep = 'init';

  // Variáveis para armazenar valores do formulário de login
  appKey = '';
  appSecret = '';
  isAuthorized = false;

  constructor(
    private openFinanceService: OpenFinanceService,
    private customerService: CustomerService,
    private erpsService: ErpsService,
    private router: Router, 
    public dialogRef: MatDialogRef<CustomerErpsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { customer: Customer }
  ) { }

  async ngOnInit(): Promise<void> {

    if (this.data?.customer) {
      this.customer = this.data.customer; // Obtém o customer passado pelo diálogo
      this.customerUid = this.customer?.uid;
    }

    this.userSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;
    });


    // Obtém os ERPs configurados dinamicamente
    this.erpsService.getErpsConfig().subscribe((erps: any[]) => {
      this.erpsList = erps;  // Atualiza a lista de ERPs com os dados do serviço
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  goRedirect(mnemonico: string, urlRedirect?: string): void {

    if (mnemonico === 'contaAzul') {
      window.open(`${ urlRedirect }${ this.customerUid }`, 'noopener noreferrer');
    }else{
      this.goToNextStep(mnemonico)
    }
    
  }

  // Método para alternar para a próxima etapa
  goToNextStep(step: string): void {
    this.currentStep = step; // Define a etapa como a segunda
  }

  // Fecha o modal
  closeModal(): void {
    this.dialogRef.close();
  }

  // Método para logar com os valores do formulário
  async login(): Promise<void> {
    if (!this.appKey || !this.appSecret) {
      console.error('appKey e senha são obrigatórios.');
      return;
    }

    if (!this.isAuthorized) {
      console.error('Você precisa autorizar a busca de dados.');
      return;
    }

    // Exemplo de chamada de serviço para login
    console.log('Logando com os dados:', {
      appKey: this.appKey,
      appSecret: this.appSecret,
      isAuthorized: this.isAuthorized
    });

    const erps = {
      omie: {
        appKey: this.appKey,
        appSecret: this.appSecret,
      }
    }

    this.customer.erps = erps
    await this.customerService.updateCustomerOnFirestore(this.customer.uid, this.customer);
    await this.erpsService.getDataOmie(this.customer.uid);

    
  }
}
