import firebase from 'firebase';

export class CarouselCardsEntity {
  backgroundColor: string;
  imageLink: string;
  subText: string;
  text: string;
  textColor: string;
  type: string;
  revenueMax: number;
  revenueMin: number;
  link: string;
  carouseldate: firebase.firestore.Timestamp;
  taxRegimes: string;
  guarantees: string;
  purpose: string;
  visiblePanel: string;
  name: string;
  id: string;

  constructor(
    backgroundColor = "#fff",
    imageLink = " - ",
    subText= " - ",
    text = " - ",
    textColor = "#000",
    type = "partner",
    revenueMax = 0,
    revenueMin = 0,
    link = '',
    taxRegimes: '',
    guarantees: '',
    purpose: '',
    visiblePanel: '',
    name: '',
    id: ''
  ) {
    this.backgroundColor = backgroundColor;
    this.imageLink = imageLink;
    this.subText = subText;
    this.text = text;
    this.textColor = textColor;
    this.type = type;
    this.revenueMax = revenueMax;
    this.revenueMin = revenueMin;
    this.link = link;
    this.carouseldate = firebase.firestore.Timestamp.now();
    this.taxRegimes = taxRegimes;
    this.guarantees = guarantees;
    this.id = id;
    this.purpose = purpose;
    this.visiblePanel = visiblePanel;
    this.name = name
  }
}
