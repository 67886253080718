<div class="receivables-container">
  <table class="receivables-table">
    <thead>
      <tr>
        <th>Data de Recebimento</th>
        <th>Forma de Pagamento</th>
        <th>Parcela</th>
        <th>Taxa da Operação</th>
        <th>Valor Parcela</th>
        <th>Valor Líquido</th>
        <th>Valor Recebido</th>
        <th>Valor Total</th>
        <th>Situação</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of formattedData">
        <td>{{ item.recebimento }}</td>
        <td>{{ item.formaPagamento }}</td>
        <td>{{ item.parcela }}</td>
        <td>{{ item.taxaOperacao }}</td>
        <td>{{ item.valorParcela | currency:'BRL' }}</td>
        <td>{{ item.valorLiquido | currency:'BRL' }}</td>
        <td>{{ item.valorRecebido | currency:'BRL' }}</td>
        <td>{{ item.valorTotal | currency:'BRL' }}</td>
        <td [ngClass]="{'quitado': item.situacao === 'quitado', 'aberto': item.situacao === 'aberto'}">
          {{ item.situacao }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
