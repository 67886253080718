import { Component, Input, OnInit } from '@angular/core';
import { ErpsService } from '../../customer/services/erps.service';
import { ReceivablesFormatter } from './customer-erps-result';


@Component({
  selector: 'app-customer-erps-result-admin',
  templateUrl: './customer-erps-result-admin.component.html',
  styleUrls: ['./customer-erps-result-admin.component.scss']
})
export class CustomerErpsResultComponentAdmin implements OnInit {
  @Input() customer: string | null = null;
  formattedData: any[] = [];

  constructor(private erpService: ErpsService) {}

  ngOnInit(): void {
    if (this.customer) {
      this.erpService.getErpsResult(this.customer).subscribe(data => {
        // Verifica se a resposta contém o campo "omie"
        let resultData = null;
        if (data.omie && Array.isArray(data.omie.movimentos)) {
          resultData = data.omie.movimentos; // Acessa o array "movimentos" dentro de "omie"
        } else if (data.contaAzul) {
          resultData = data.contaAzul; // Caso os dados venham do campo "contaAzul"
        }

        // Logar os dados para entender a estrutura
        console.log('Dados recebidos:', resultData);

        // Verifica se resultData é um array
        if (Array.isArray(resultData)) {
          // Formata os dados se for um array
          this.formattedData = ReceivablesFormatter.format(resultData);
        } else {
          // Caso contrário, exibe um erro ou lida com os dados de outra forma
          console.error('Esperava-se um array, mas os dados são:', resultData);
        }
      });
    }
  }
}