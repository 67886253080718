import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CarouselCardsEntity } from '../../../../functions/src/models/general/carousel-cards.entity';

@Component({
  selector: 'app-carousel-preview',
  templateUrl: './carousel-preview.component.html',
  styleUrls: ['./carousel-preview.component.scss']
})
export class CarouselPreviewComponent implements OnInit {
  items: CarouselCardsEntity[] = [];
  type: string;
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CarouselPreviewComponent>) {
    if (!data) {
      console.warn('Nenhum dado foi passado para o modal.');
    }
   }

  ngOnInit(): void {
   
  }

  onClose(): void {
    this.dialogRef.close(); 
  }
 

  openExternalLink(url: string): void {
    window.open(url, '_blank', 'noopener noreferrer');
  }
}
