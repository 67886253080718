import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { LogService } from 'src/app/components/logger/log.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserData } from 'src/app/customer/containers/custom-email-actions/custom-email-actions';
import { environment } from 'src/environments/environment';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { CustomerErpsConfirmDialogComponent } from '../components/customer-erps-confirm-dialog/customer-erps-confirm-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ErpsService {
  userData: Observable<UserData>;

  constructor(
    private http: HttpClient,
    private logger: LogService,
    private dialog: MatDialog,
    private authService: AuthService,
    private firestore: AngularFirestore,
  ) {
    this.logger.controllerName = this.constructor.name;
  }

  
  getDataOmie(customer?: string): Promise<void> {
    const omiePromise = this.http
      .post(
        `${ environment.functionsUrl }/integrationsapi/omie/consultOmie`,
        customer,
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();

    return Promise.all([omiePromise/*, sendNotificationPromise*/])
      .then(() => {
        console.log('get data feito');

        this.dialog.open(CustomerErpsConfirmDialogComponent, {
          maxWidth: '600px',
          data: {
            title: 'Omnie Conectada com sucesso!',

          },
        });

      })
      .catch((err) => {
        console.error('Erro ao coletar informações da omie', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao coletar informações da Omie',
            alertDescription: `Ocorreu um erro ao coletar informações da Omie. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
            isOnlyConfirm: true
          },
        });

        throw new Error('error-omie');
      });
  }

  getDataContaAzul(accessToken: string, uid: string): Promise<void> {
    const contaAzulPromise = this.http
      .post(
        `${ environment.functionsUrl }/integrationsapi/contaAzul/consultContaAzul`,
        {
          accessToken: accessToken,
          uid: uid
        },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise();

    return Promise.all([contaAzulPromise/*, sendNotificationPromise*/])
      .then(() => {
        console.log('get data feito');

        this.dialog.open(CustomerErpsConfirmDialogComponent, {
          maxWidth: '600px',
          data: {
            title: 'Conta Azul Conectada com sucesso!',
          },
        });

      })
      .catch((err) => {
        console.error('Erro ao coletar informações do Conta Azul', err);

        this.dialog.open(AlertDialogComponent, {
          maxWidth: '600px',
          data: {
            alertTitle: 'Erro ao coletar informações do Conta Azul',
            alertDescription: `Ocorreu um erro ao coletar informações do Conta Azul. Erro: ${ err.error.error.message ?? 'Erro desconhecido' }`,
            isOnlyConfirm: true
          },
        });

        throw new Error('error-contaAzul');
      });
  }

  getTokenContaAzul(authorizationCode: string): Promise<string> {
    return this.http
      .post(
        `${environment.functionsUrl}/integrationsapi/contaAzul/generateAccessToken`,
        { authorizationCode },
        { responseType: 'json', headers: this.authService.getHeader() }
      )
      .toPromise()
      .then((response: any) => {
        if (response && response.accessToken) {
          console.log('Token gerado:', response.accessToken);
          return response.accessToken;
        } else {
          throw new Error('Token de acesso não encontrado na resposta.');
        }
      })
      .catch((err) => {
        console.error('Erro ao coletar informações do Conta Azul:', err);
        throw new Error('error-contaAzul');
      });
  }


  getErpsConfig(): Observable<any> {
    return this.firestore.collection('erps-config').valueChanges();
  }

  getErpsResult(uid: string): Observable<any> {
    return this.firestore.collection('erps-data-history').doc(uid).valueChanges();
  }
  
  // saveErpsData(uid: string, data: any): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     this.firestore
  //       .collection('erps-data-history')
  //       .doc(uid)
  //       .update({
  //         : data // Salva dentro do campo "omie" no documento
  //       })
  //       .then(() => {
  //         console.log(`Dados salvos com sucesso para o UID: ${uid}`);
  //         resolve();
  //       })
  //       .catch((error) => {
  //         console.error('Erro ao salvar os dados no Firestore:', error);
  //         reject(error);
  //       });
  //   });
  // }

  

}
