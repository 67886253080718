<div class="home-container" *ngIf="!isBabyCustomer">
  <div class="inner-container">
    <div class="main-content">
      <div class="home-content">
        <div>
          <h1 style="flex: 1" class="mb-4">Olá, {{ customer?.name || '' | uppercase }}</h1>
          <p *ngIf="customer?.revenue >= 360000 && !isCustomerNoTouch">
            Bem vindo à <strong>Capital Empreendedor</strong>! Por favor, carregue seus documentos na aba <a routerLink="/documentacao" class="highlight">Documentos</a>. Quanto
            mais rapidamente os documentos estiverem disponíveis, maiores suas chances de obter o empréstimo mais
            barato e mais rápido!
          </p>
          <p *ngIf="customer?.revenue < 360000 && !isCustomerNoTouch">
            Bem vindo à Capital Empreendedor! Um de nossos parceiros entrará em contato para solicitar a documentação.
            Quanto mais rapidamente os documentos estiverem disponíveis, maiores suas chances de obter o empréstimo
            mais barato e mais rápido!
          </p>
          <p *ngIf="!isCustomerNoTouch">
            Após enviados os documentos, acompanhe novas oportunidades de crédito para você na aba
            <a routerLink="/credito" class="highlight">Ofertas de crédito</a>. Sempre que receber uma nova proposta, ela aparecerá lá!
          </p>

          <p *ngIf="isCustomerNoTouch">Boas vindas à <b>Capital Empreendedor</b>! Estamos dandos andamento ao seu pedido de crédito, em breve um de nossos parceiros entrará em contato.</p>
          <p *ngIf="isCustomerNoTouch">Acompanhe suas novas oportunidades de crédito na aba <a class="highlight">Ofertas de crédito</a>. Sempre que receber uma nova proposta, ela aparecerá lá!</p>

          <mat-accordion class="example-headers-align" *ngIf="showMissingSignatureCard">
            <mat-expansion-panel class="carta-scr">
              <mat-expansion-panel-header>
                <mat-panel-title>Assinatura pendente</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="content">
                <p><span>Assine a autorização do Banco Central.</span><br /> Para dar andamento em seu pedido de crédito, os bancos precisam de algumas informações relacionadas ao Banco Central.
                  A carta SCR é um instrumento de registro gerido pelo Banco Central e alimentado mensalmente pelas instituições financeiras,
                  que auxilia na análise de crédito em andamento.</p>
                <button [disabled]="isClicksignWidgetOpen" mat-raised-button style="width: fit-content; align-self: start" (click)="openClicksignWidget(signatureCardObject.typeId, signatureCardObject.description)">
                  Assinar
                </button>
                <mat-spinner [diameter]="18" color="accent" class="d-inline-block mx-3"
                  *ngIf="isClicksignWidgetOpen">
                </mat-spinner>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

        </div>

        <!-- <div class="open-finance-wrapper" *ngIf="customer.revenue >= 1000000">
          <p>Agilize sua análise de crédito utilizando o <strong>Open Finance</strong>, clicando abaixo:</p>
          <button class="open-finance-btn" id="open-finance-btn" onclick="startPluggyConnect(this.getAttribute('data-access-token'), this.getAttribute('data-customer-uid'), this.getAttribute('data-url-functions'))">Acelere sua análise!</button>
          <a class="open-finance-btn"  mat-raised-button (click)="redirectToOpenFinance()">Acelere sua análise!</a>
        </div> -->

        <div >
          <mat-card class="card-ofertas-container">
            <mat-card-title>Oferta de crédito disponível!</mat-card-title>
            <mat-card-content>
              <p>Parabéns! Acesse agora para aproveitar as melhores taxas e prazos para impulsionar sua empresa.</p>

              <button class="button-oferta" routerLink="/credito">
                Ver ofertas
                <img class="img-arrow" src="../../../assets/images/icons/Arrow Right.png" alt="">
              </button>
            </mat-card-content>
          </mat-card>

        </div>

        <div class="doubts-wrapper">
          <div *ngIf="hasOppsByRecurrence">
            <br>
            <button class="buttonCredit">
              <a (click)="openDialog()">Quero novo crédito</a>
            </button>
          </div>
        </div>
        <div class="docs-pending-card" *ngIf="pendingDocumentsLength > 0">
          <h3>Você tem {{pendingDocumentsLength}} documentos pendentes</h3>
          <p>Por favor, nos envie estes documentos (disponíveis com o seu contador) para dar seguimento a sua operação de crédito!</p>
          <button routerLink="/documentacao">Enviar documentos</button>
        </div>
      </div>

      <div class="text-center" *ngIf="enableLoading">
        <mat-spinner diameter="60" class="mx-auto"></mat-spinner>
      </div>
      <!-- <div class="cupom-wrapper" *ngIf="!enableLoading">
        <h2>Cupom</h2>
        <app-user-cupom></app-user-cupom>
      </div> -->

      <!-- <div class="data-aggregator-container" *ngIf="customer.revenue >= 500000">

        <app-carrossel [type]="'faq'" [customer]="this.customer"></app-carrossel>
      </div> -->

      <div class="add-guarantees-card">
        <h3>Receba mais ofertas!</h3>
        <p>Adicione um imóvel e/ou veículo como garantia para receber mais ofertas e melhores condições de crédito!</p>
        <h4>Adicionar um:</h4>
        <div class="guarantees-images-row">
          <button (click)="onClickAddGuarantee('veículo')"><img src="../../../../assets/images/home/garantia-veiculo.png" alt="garantia-veiculo-picture"></button>
          <button (click)="onClickAddGuarantee('imóvel')"><img src="../../../../assets/images/home/garantia-imovel.png" alt="garantia-imovel-picture"></button>
          <button (click)="onClickAddGuarantee('veículo e imóvel')"><img src="../../../../assets/images/home/garantia-vei-imo.png" alt="garantia-veiculo-imovel-picture"></button>
          <button (click)="onClickAddGuarantee('recebíveis')"><img src="../../../../assets/images/home/garantia-recebiveis.png" alt="garantia-recebiveis-picture"></button>
        </div>
      </div>
    </div>

    <div class="extra-cards-container">

      <!-- <mat-card class="extra-card documents">
        <mat-card-title>Documentos pendentes</mat-card-title>
        <mat-card-content>
          <p>Você possui <b>{{pendingDocumentsLength}} documentos pendentes</b> para dar seguimento a sua operação de crédito!</p>
          <p>Por favor, nos envie estes documentos (disponíveis com o seu contador) na aba “Documentação”:</p>
          <button routerLink="/documentacao">Enviar documentos</button>
        </mat-card-content>
      </mat-card> -->

      <mat-card class="extra-card signature" *ngIf="showMissingSignatureCard">
        <mat-card-title>Assinatura pendente</mat-card-title>
        <mat-card-content>
          <p>Para prosseguir com seu pedido de crédito, precisamos da Autorização do Banco Central assinada. <b>Assine o documento</b> o quanto antes para obter o crédito mais rápido!</p>
          <button routerLink="/documentacao">Assinar autorização</button>
        </mat-card-content>
      </mat-card>
      <span>Recomendados para você:</span>
      <div class="recommended-container">

        <app-recommended-customer></app-recommended-customer>

      </div>

      <div class="exclusive-benefits-container">
        <!-- <h2>Benefícios exclusivos</h2> -->
        <app-carrossel  [type]="'service'" [customer]="this.customer"></app-carrossel>
      </div>

      <div class="extra-info">
        <p *ngIf="!isCustomerNoTouch">Acesse sua situação de crédito no <span class="highlight-green">Serasa</span>, disponível em nosso painel:</p>
        <button *ngIf="!isCustomerNoTouch"  (click)="navigateToSerasa()">Dados do Serasa</button>

        <!-- <p> <br> Quer saber como <span class="highlight">melhorar seu score</span> e conseguir <span class="highlight">mais ofertas</span> de crédito? Cheque esta e outras dicas no nosso blog:</p>
        <button><a href="https://www.capitalempreendedor.com.br/blog/score-credito-empresa" target="_blank">Acesse o blog</a></button> -->

        <!-- <p> <br> Descontente com a sua <span class="highlight">contabilidade</span>? A Capital Empreendedor tem a solução! Agora você pode fazer a sua <span class="highlight">contabilidade 100% automatizada</span>:</p>
        <button><a href="https://www.capitalempreendedor.com.br/contabilidade" target="_blank">Saiba mais!</a></button> -->
      </div>

      <div class="blog-container">
        <h4>Educação financeira</h4>
        <div class="blog-inner-container">
          <app-blog-card></app-blog-card>
          <a href="https://www.capitalempreendedor.com.br/blog" target="_blank">Mais artigos</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="home-container baby-layout" *ngIf="isBabyCustomer">
  <div class="inner-container">
    <div class="main-content">
      <h1>Olá, {{ customer?.name || '' }}</h1>
      <p>Boas vindas à <b>Capital Empreendedor!</b> Estamos empenhados em encontrar todas as oportunidades de crédito disponíveis para você!</p>
      <div class="add-guarantees-container">
        <h3>Comece a receber ofertas!</h3>
        <p>Sua empresa possui menos de 2 anos, então para conseguir chance de crédito, é necessário que adicione um imóvel e/ou veículo como garantia!</p>
        <h5>Adicionar um:</h5>
        <div class="guarantees-images-row">
          <button (click)="onClickAddGuarantee('veículo')"><img src="../../../../assets/images/home/garantia-veiculo.png" alt="garantia-veiculo-picture"></button>
          <button (click)="onClickAddGuarantee('imóvel')"><img src="../../../../assets/images/home/garantia-imovel.png" alt="garantia-imovel-picture"></button>
          <button (click)="onClickAddGuarantee('veículo e imóvel')"><img src="../../../../assets/images/home/garantia-vei-imo.png" alt="garantia-veiculo-imovel-picture"></button>
        </div>
      </div>
      <h5>Precisa de ajuda?</h5>
      <div class="help-buttons-wrapper">
        <button><a routerLink="/mensagens">Fale conosco</a></button>
        <button><a href="https://www.capitalempreendedor.com.br/faq" target="_blank">Perguntas frequentes</a></button>
      </div>
    </div>
    <div class="extra-cards-container">
      <div class="exclusive-benefits-container">
        <app-carrossel styleType="column-reverse" [type]="'service'" [customer]="this.customer"></app-carrossel>
      </div>
      <p>Quer saber como <b>melhorar seu score de crédito e a situação financeira do seu negócio?</b> Acesse nosso blog para dicas!</p>
      <button class="orange-button"><a href="https://www.capitalempreendedor.com.br/blog" target="_blank">Acesse o blog</a></button>
    </div>
  </div>
</div>











