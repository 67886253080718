<div class="container">
    <h1 class="page-title" >Configuração do Carrossel</h1>
    <div class="accordions">
    <mat-accordion>
        <mat-expansion-panel hideToggle  #panelbenefit >
            <mat-expansion-panel-header>
                <mat-panel-title >
                    <span class="title"  style="flex: 1;">Carrosséis de Benefícios</span>
                    <mat-icon>
                        {{ panelbenefit.expanded ? 'remove' : 'add' }}
                      </mat-icon>
                </mat-panel-title>
                
            </mat-expansion-panel-header>
            <div class="box">
            <div class="edit-form" style=" padding-right: 10px; color: #909090;" >
                <input matInput placeholder="Buscar por nome" class="input" [(ngModel)]="searchCardbenefit"  (input)="onInputCardBenefit()" (keyup.enter)="applyFilter()"/>
                <mat-icon class="search-icon" (click)="applyFilter()">search</mat-icon>
               
            </div>
            <button class="new-button" (click)="openNewItemModal()">Novo
                <mat-icon >add</mat-icon>
            </button>
        </div>
        <table mat-table mat-table [dataSource]="dataSourceCardbenefits"  >
              <!-- Definição da coluna 'Carrossel beneficios' -->
              <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef > </th>
                <td mat-cell  class="first-column" *matCellDef="let item"> {{ item.text }} </td>
              </ng-container>
          
              <!-- Definição da coluna 'searchIcon' -->
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element" >
                  <a mat-flat-button matTooltip="Editar" style="border: none;" class="label" (click)="onEditData(element)">
                    <img src="../../../assets/images/icons/picker.png" alt="" >
                    Editar
                  </a>
                </td>
              </ng-container>

               <!-- Definição da coluna 'Preview' -->
               <ng-container matColumnDef="preview">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let preview" >
                  <a mat-flat-button matTooltip="Preview" style="border: none;" class="label" (click)="openCardModal(preview)">
                    <img src="../../../assets/images/icons/Eye.png" alt="">
                    Preview
                  </a>
                </td>
              </ng-container>


               <!-- Definição da coluna 'Ativo' -->
               <ng-container matColumnDef="ativo">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let cg">
                  <mat-checkbox
                    class="label-ativo custom-checkbox"
                    [(ngModel)]="cg.ativo"
                    (change)="onStatusChange(cg)">
                    {{ cg.ativo ? 'Ativo' : 'Inativo' }}
                  </mat-checkbox>
                </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" 
                ></tr>
             
        </table>
        </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
        <mat-expansion-panel hideToggle #panelCardData>
            <mat-expansion-panel-header   >
                <mat-panel-title >
                    <span class="title"  style="flex: 1;">Carrosséis de Dados</span>
                    <mat-icon>
                        {{ panelCardData.expanded ? 'remove' : 'add' }}
                      </mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="box">
            <div class="edit-form" style=" padding-right: 10px; color: #909090;" >
                <input matInput placeholder="Buscar por nome" class="input" [(ngModel)]="searchCardData"  (input)="onInputCardData()" (keyup.enter)="applyFilter()" />
                <mat-icon class="search-icon" (click)="applyFilter()">search</mat-icon>
            </div>
            <button class="new-button" (click)="openNewItemModal()">Novo
                <mat-icon >add</mat-icon>
            </button>
            </div>

            <table mat-table mat-table [dataSource]="dataSourceCardDatas"  >
                <!-- Definição da coluna 'Carrossel Dados' -->
                <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef > </th>
                  <td mat-cell  class="first-column" *matCellDef="let item"> {{ item.text }} </td>
                </ng-container>
            
                <!-- Definição da coluna 'searchIcon' -->
                <ng-container matColumnDef="edit">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let element" >
                    <a mat-flat-button matTooltip="Editar" style="border: none;" class="label" (click)="onEditData(element)">
                      <img src="../../../assets/images/icons/picker.png" alt="">
                      Editar
                    </a>
                  </td>
                </ng-container>
  
                 <!-- Definição da coluna 'Preview' -->
                 <ng-container matColumnDef="preview">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let preview" >
                    <a mat-flat-button matTooltip="Preview" style="border: none;" (click)="openCardModal(preview)">
                      <img src="../../../assets/images/icons/Eye.png" alt="" class="label">
                      Preview
                    </a>
                  </td>
                </ng-container>
                 <!-- Definição da coluna 'Ativo' -->
                 <ng-container matColumnDef="ativo">
                  <th mat-header-cell *matHeaderCellDef>  </th>
                  <td mat-cell *matCellDef="let cg">
                    <mat-checkbox
                      class="label-ativo"
                      [(ngModel)]="cg.ativo"
                      (change)="onStatusChange(cg)">
                      {{ cg.ativo ? 'Ativo' : 'Inativo' }}
                    </mat-checkbox>
                  </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" 
                  ></tr>
               
          </table>
        </mat-expansion-panel>
    </mat-accordion>
</div>
</div>