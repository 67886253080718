<div mat-dialog-title class="modal-header">
    <button mat-icon-button (click)="onClose()" class="close-btn">
      <mat-icon>close</mat-icon> 
    </button>
  </div>
<div class="carousel-wrapper" #carouselWrapper>
    <div class="carousel">
        <!--  -->
      <ng-container >
        <div  class="carousel-item">
            <img [src]="data.imageLink" [alt]="data.text">
          <h3>{{ data.text }}</h3>
          <p>{{ data.subText }}</p>
          <a class="link-button" [href]="data.link" (click)="openExternalLink(data.link)" target="_blank" rel="noopener noreferrer">
            Ver mais ➜
          </a>

        </div>
      </ng-container>
    </div>
  </div>
