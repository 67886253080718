import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Customer } from '../../../../../functions/src/models/Customer';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-customer-erps-confirm-dialog',
  templateUrl: './customer-erps-confirm-dialog.component.html',
  styleUrls: ['./customer-erps-confirm-dialog.component.scss']
})
export class CustomerErpsConfirmDialogComponent implements OnInit {
  isOpen = false;
  customerUid: string;
  customer: Customer;
  userSubscription: Subscription;

  // Gerencia o estado do diálogo
  currentStep = 1;

  // Título dinâmico
  title: string;

  constructor(
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<CustomerErpsConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title?: string } // Recebe os dados do diálogo
  ) {
    this.title = data.title || 'Conta Azul Conectada com sucesso!';
  }

  async ngOnInit(): Promise<void> {
    this.userSubscription = this.customerService.customer.subscribe(async (customer) => {
      this.customer = customer;
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
